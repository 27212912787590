<template>
  <div class="container">
    <div class="screen">
      <div class="logo">
        <svg @load="isImageLoaded" width="200" height="95" viewBox="0 0 486 227" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.66 89.04H50.37V69.91H0V225.31H50.37V206.18H20.66V89.04Z" fill="black"/>
          <path d="M66.77 125.92H50.37V144.88H66.77V225.31H87.43V69.91H66.77V125.92Z" fill="black"/>
          <path d="M188.24 125.92H176.97V144.88H188.24V225.31H208.9V69.91H188.24V125.92Z" fill="black"/>
          <path d="M217.24 188.34H229.53V226.08H250.19V188.34H263.68V226.08H284.34V188.34H297.49V169.22H217.24V188.34Z" fill="black"/>
          <path d="M389.1 69.91H368.44V225.31H389.1V69.91Z" fill="black"/>
          <path d="M485.05 69.91H464.39V225.31H485.05V69.91Z" fill="black"/>
          <path d="M418.45 89.04H448.17V69.91H397.79V225.31H448.17V206.18H418.45V151.71H448.34V132.58H418.45V89.04Z" fill="black"/>
          <path d="M121.24 69.97V90.15C137.82 90.15 151.31 102.75 151.31 118.25L151.33 132.92H121.25V152.05H151.35L151.43 225.32L171.61 225.3L171.5 118.25C171.5 91.63 148.96 69.97 121.25 69.97H121.24Z" fill="black"/>
          <path d="M325.49 176.96L325.38 69.9L305.2 69.92L305.31 176.97C305.31 203.59 327.85 225.25 355.56 225.25V205.07C338.98 205.07 325.49 192.46 325.49 176.96Z" fill="black"/>
          <path d="M296.01 160.25V108.88C296.01 108.37 295.95 107.87 295.93 107.36V70.69C295.93 70.69 219.78 70.69 219.54 70.69V108.7C219.54 108.7 219.54 108.77 219.54 108.81V110.23L219.62 110.17V160.25C219.62 160.25 295.77 160.25 296.01 160.25ZM275.16 139.39H240.29V91.21H275.16V139.39Z" fill="black"/>
          <path d="M57.05 38.72L51.69 36.04C54.5 30.74 56.43 25.06 57.49 18.99C58.55 12.92 59.07 6.73 59.07 0.41H64.91C64.91 2.63 64.85 4.86 64.73 7.11C64.61 9.36 64.42 11.61 64.16 13.86C64.63 15.74 65.23 17.63 65.96 19.52C66.69 21.41 67.54 23.24 68.51 25C69.45 26.79 70.49 28.49 71.65 30.11C72.81 31.73 74.04 33.18 75.36 34.48L70.97 38.41C69.18 36.56 67.55 34.44 66.08 32.06C64.6 29.67 63.34 27.19 62.28 24.6C61.67 27.1 60.93 29.54 60.06 31.92C59.2 34.31 58.19 36.58 57.05 38.74V38.72ZM85.76 20.89V39.93H79.92V0H85.76V15.02H90.68V20.89H85.76Z" fill="black"/>
          <path d="M126.19 0H132.03C132.03 1.23 131.96 2.45 131.83 3.65C131.7 4.85 131.52 6.01 131.28 7.12C132.51 9.06 134.07 10.9 135.98 12.62C137.88 14.35 139.84 15.76 141.86 16.87L138.39 21.4C136.72 20.41 135.08 19.21 133.47 17.8C131.86 16.38 130.42 14.87 129.17 13.27C128.29 15.12 127.24 16.8 126.01 18.33C124.78 19.85 123.39 21.2 121.84 22.37L117.49 18.4C120.36 16.37 122.52 13.79 123.99 10.66C125.45 7.54 126.19 3.98 126.19 0.01V0ZM142.52 40.34H129.92C126.85 40.34 124.42 39.64 122.65 38.24C120.88 36.84 119.99 34.8 119.99 32.12C119.99 29.71 120.88 27.74 122.65 26.2C124.42 24.66 126.84 23.89 129.92 23.89H142.52C145.53 23.89 147.93 24.66 149.72 26.2C151.5 27.74 152.4 29.71 152.4 32.12C152.4 34.8 151.51 36.84 149.72 38.24C147.93 39.64 145.53 40.34 142.52 40.34ZM129.92 35.16H142.52C143.66 35.16 144.6 34.88 145.33 34.33C146.06 33.78 146.43 33.04 146.43 32.11C146.43 31.18 146.06 30.48 145.33 29.91C144.6 29.34 143.66 29.05 142.52 29.05H129.92C128.69 29.05 127.73 29.34 127.04 29.91C126.35 30.48 126.01 31.21 126.01 32.11C126.01 33.01 126.35 33.77 127.04 34.33C127.73 34.89 128.69 35.16 129.92 35.16ZM156.53 7.85V13.67H151.61V22.63H145.77V0H151.61V7.85H156.53Z" fill="black"/>
          <path d="M185.46 0.65H203.81V3.75C203.81 6.25 203.36 8.55 202.45 10.66C201.54 12.77 200.28 14.61 198.67 16.18C197.06 17.75 195.16 19.04 192.96 20.04C190.76 21.04 188.39 21.68 185.85 21.96C185.65 21.56 185.45 21.15 185.26 20.74C185.07 20.32 184.89 19.92 184.71 19.51C184.53 19.08 184.35 18.65 184.16 18.24C183.97 17.82 183.79 17.4 183.61 16.97C187.41 16.54 190.61 15.39 193.2 13.53C195.79 11.67 197.36 9.16 197.92 6.02H185.45V0.65H185.46ZM218.25 23.8V39.7H186.69V23.8H218.25ZM192.52 34.56H212.45V28.92H192.52V34.56ZM218.25 0V22H212.46V14.28H204.21V8.69H212.46V0H218.25Z" fill="black"/>
          <path d="M249.86 18.3H286.17V23.29H249.86V18.3ZM284.32 0.650024V5.64002H271.98C271.78 5.98002 271.56 6.30002 271.34 6.61002C271.12 6.92002 270.89 7.21002 270.66 7.49002C272.71 8.78002 275.05 9.81003 277.68 10.56C280.31 11.32 283.01 11.79 285.76 11.97L283.52 16.54C280.71 16.26 277.82 15.65 274.85 14.69C271.88 13.73 269.3 12.5 267.1 10.99C265.05 12.65 262.77 14.02 260.25 15.08C257.73 16.14 255.25 16.81 252.79 17.09L250.29 12.61C253.19 12.27 255.93 11.51 258.52 10.32C261.11 9.13002 263.34 7.57002 265.22 5.63002H251.7V0.650024H284.32ZM284.71 33.04C284.71 35.41 283.85 37.22 282.14 38.47C280.43 39.72 278.06 40.34 275.05 40.34H261C257.93 40.34 255.54 39.72 253.84 38.47C252.14 37.22 251.29 35.41 251.29 33.04C251.29 30.67 252.14 28.97 253.84 27.66C255.54 26.35 257.92 25.7 261 25.7H275.05C278.06 25.7 280.43 26.36 282.14 27.66C283.85 28.97 284.71 30.76 284.71 33.04ZM278.74 33.04C278.74 32.3 278.43 31.7 277.8 31.24C277.17 30.78 276.15 30.55 274.75 30.55H261.27C259.95 30.55 258.96 30.78 258.29 31.24C257.62 31.7 257.28 32.3 257.28 33.04C257.28 33.78 257.59 34.37 258.22 34.82C258.85 35.27 259.87 35.49 261.27 35.49H274.75C276.16 35.49 277.17 35.27 277.8 34.82C278.43 34.37 278.74 33.78 278.74 33.04Z" fill="black"/>
          <path d="M352.01 24.07V29.75H336.95V40.33H330.76V29.75H315.7V24.07H352.01ZM350.17 21.49H344.33V6.28002H317.55V0.650024H350.17V21.49Z" fill="black"/>
          <path d="M382 11.83V10.77C382 9.23 382.26 7.8 382.77 6.47C383.28 5.14 383.99 4.01 384.9 3.05C385.84 2.1 386.95 1.35 388.24 0.81C389.53 0.27 390.95 0 392.5 0C394.05 0 395.43 0.27 396.71 0.81C397.99 1.35 399.11 2.1 400.05 3.05C400.99 4.01 401.72 5.15 402.25 6.47C402.78 7.79 403.04 9.23 403.04 10.77V11.83C403.04 13.4 402.78 14.84 402.25 16.15C401.72 17.46 400.99 18.59 400.05 19.55C399.11 20.51 398 21.24 396.71 21.77C395.42 22.3 394.02 22.55 392.5 22.55C390.98 22.55 389.53 22.29 388.24 21.77C386.95 21.25 385.84 20.51 384.9 19.55C383.99 18.6 383.28 17.46 382.77 16.15C382.26 14.84 382 13.4 382 11.83ZM415.8 25.74V40.34H410.01V31.24H383.85V25.74H415.81H415.8ZM387.75 10.77V11.83C387.75 13.4 388.17 14.73 389 15.8C389.83 16.87 391 17.42 392.49 17.42C393.98 17.42 395.11 16.88 395.96 15.8C396.81 14.72 397.23 13.4 397.23 11.83V10.77C397.23 9.2 396.81 7.87 395.96 6.79C395.11 5.71 393.96 5.17 392.49 5.17C391.02 5.17 389.83 5.71 389 6.79C388.17 7.87 387.75 9.2 387.75 10.77ZM410 3.88V0H415.79V23.89H410V18.99H404.12V13.58H410V9.33H404.12V3.88H410Z" fill="black"/>
          <path d="M433.36 13.03H426.69V6.28003H433.36V13.03ZM433.36 32.99H426.69V26.24H433.36V32.99Z" fill="black"/>
        </svg>
      </div>
      <ChangingSymbol />
      <div class="start-button">
<!--        <p class="texts">{{ participantCount + 1}} 번째 참가자</p>-->
        <button @click="startSurvey">사상검증 참가하기</button>
        <button @click="shareKakao" style="font-size: 14px; background-color: #FFFFFF; font-weight: 500; border-radius: 8px; border: 1px solid #d38e00; color: #d38e00; height: 30px;">카카오톡 공유하기</button>
        <button @click="shareLink" style="font-size: 14px; background-color: #FFFFFF; font-weight: 500; border-radius: 8px; border: 1px solid #6e6e6e; color: #6e6e6e; height: 30px;">링크 공유하기</button>
      </div>
    </div>
  </div>
</template>

<script>
import ChangingSymbol from "@/components/ChangingSymbol";
import supabase from '@/helper/supabaseClient.js'
import {useKakao} from "vue3-kakao-sdk";

export default {
  name: "HomePage",
  components: {
    ChangingSymbol
  },
  data() {
    return{
      participantCount: 0
    }
  },
  async mounted() {
    window.scrollTo(0, 0);
    await this.fetchParticipantCount();
  },
  setup() {
    const { kakao } = useKakao();
    const currentUrl = window.location.href;

    const shareKakao = () => {
      kakao.value.Link.sendDefault({
        objectType: 'feed',
        content: {
          title: `[더 커뮤니티] 사상검증 테스트`,
          description: '사상검증 테스트 하러가기',
          imageUrl: 'https://i.imgur.com/NSFxmYK.jpeg',
          link: {
            mobileWebUrl: currentUrl,
            webUrl: currentUrl
          }
        },
      });
    };
    return { shareKakao };
  },
  methods:{
    async fetchParticipantCount() {
      const { data, error } = await supabase
          .from('users')
          .select('*', { count: 'exact' });

      if (error) {
        console.error('Error fetching participant count:', error);
      } else {
        this.participantCount = data.id;
      }
    },
    startSurvey() {
      this.$router.push({ path: '/intro' });
    },
    shareLink() {
      const link = window.location.href;
      navigator.clipboard.writeText(link);
      alert('링크가 복사되었습니다');
    },
  }
}
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* 부모 컨테이너의 높이를 전체 뷰포트 높이로 설정 */
  max-width: 480px;
  max-height: 2160px;
  font-size: 0;
  overflow: hidden;
  margin: 0 auto;
  background-color: #FCFCFC;

}

.screen {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FCFCFC;
  overflow: hidden;
}

.logo{
  margin-bottom: 30px;
}

.start-button{
  margin-top: 45px;
}

.texts{
  font-size: 12px;
  color: #707070;
}

.start-button{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.start-button button{
  background-color: #1f1f1f;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  height: 50px;
  font-weight: 700;
  width: 200px;
}

</style>