<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
html,body{
  height: 100vh;
  align-items: center;
  -webkit-backface-visibility:  hidden;
  -webkit-touch-callout : none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  min-height: 100vh;
  background-color: #FCFCFC;
  margin: 0;
  padding: 0;
}
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

button{
  -webkit-appearance: none; /* remove default appearance on Webkit-based browsers */
  -moz-appearance: none; /* remove default appearance on Mozilla-based browsers */
  appearance: none; /* remove default appearance on all other browsers */
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust : none;  /* 크롬, 사파리, 오페라 신버전 */
  -ms-text-size-adjust : none;  /* IE */
  -moz-text-size-adjust : none;  /* 파이어폭스 */
  -o-text-size-adjust : none;  /* 오페라 구버전 */
  font-family: inherit;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
}


#app {
  font-family: 'SUIT','Pretendard-Regular', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin: 0;
  padding: 0;
  background-color: white;
}


input {
  font-family: inherit;
  outline:none;
  border:none;
  resize: none;
  background: none;
  font-size:inherit;
  font-weight:inherit;
  color:inherit;
  line-height: inherit;
  border-radius: 0;
}

*::-webkit-scrollbar {
  display: none;
}

</style>
