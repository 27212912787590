<template>
  <div class="container">
    <div class="screen">
      <div v-if="isLoading" class="loading-screen">
        <LoadingSymbol/>
        <span style="color: white; font-size: 18px; font-weight: bold;">
          결과를 분석중입니다
        </span>
        <div class="loading-logo">
          <svg width="80" height="44" viewBox="0 0 505 227" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.33 89.04H51.04V69.91H0.669983V225.31H51.04V206.18H21.33V89.04Z" fill="white"/>
            <path d="M67.43 125.92H51.04V144.88H67.43V225.31H88.1V69.91H67.43V125.92Z" fill="white"/>
            <path d="M504.67 69.91H484.01V225.31H504.67V69.91Z" fill="white"/>
            <path d="M438.08 89.04H467.79V69.91H417.42V225.31H467.79V206.18H438.08V151.71H467.96V132.58H438.08V89.04Z" fill="white"/>
            <path d="M189.91 125.92H178.64V144.88H189.91V225.31H210.57V69.91H189.91V125.92Z" fill="white"/>
            <path d="M122.91 69.97V90.15C139.49 90.15 152.98 102.75 152.98 118.25L153 132.92H122.92V152.05H153.02L153.1 225.32L173.28 225.3L173.17 118.25C173.17 91.63 150.63 69.97 122.92 69.97H122.91Z" fill="white"/>
            <path d="M404.74 69.91H384.08V225.31H404.74V69.91Z" fill="white"/>
            <path d="M341.13 176.96L341.02 69.9L320.84 69.92L320.95 176.97C320.95 203.59 343.49 225.25 371.2 225.25V205.07C354.62 205.07 341.13 192.46 341.13 176.96Z" fill="white"/>
            <path d="M225.89 188.34H238.18V226.08H258.84V188.34H272.34V226.08H293V188.34H306.15V169.22H225.89V188.34Z" fill="white"/>
            <path d="M304.67 160.25V108.88C304.67 108.37 304.61 107.87 304.59 107.36V70.69C304.59 70.69 228.44 70.69 228.2 70.69V108.7C228.2 108.7 228.2 108.77 228.2 108.81V110.23L228.28 110.17V160.25C228.28 160.25 304.43 160.25 304.67 160.25ZM283.81 139.39H248.94V91.21H283.81V139.39Z" fill="white"/>
            <path d="M67.19 38.72L61.83 36.04C64.64 30.74 66.57 25.06 67.63 18.99C68.68 12.92 69.21 6.73 69.21 0.41H75.05C75.05 2.63 74.99 4.86 74.87 7.11C74.75 9.36 74.56 11.61 74.3 13.86C74.77 15.74 75.37 17.63 76.1 19.52C76.83 21.41 77.68 23.24 78.65 25C79.59 26.79 80.63 28.49 81.79 30.11C82.95 31.73 84.18 33.18 85.5 34.48L81.11 38.41C79.32 36.56 77.69 34.44 76.22 32.06C74.74 29.67 73.48 27.19 72.42 24.6C71.81 27.1 71.07 29.54 70.2 31.92C69.34 34.31 68.33 36.58 67.19 38.74V38.72ZM95.91 20.89V39.93H90.07V0H95.91V15.02H100.83V20.89H95.91Z" fill="white"/>
            <path d="M136.34 0H142.18C142.18 1.23 142.11 2.45 141.98 3.65C141.85 4.85 141.67 6.01 141.43 7.12C142.66 9.06 144.22 10.9 146.13 12.62C148.03 14.35 149.99 15.76 152.01 16.87L148.54 21.4C146.87 20.41 145.23 19.21 143.62 17.8C142.01 16.38 140.57 14.87 139.32 13.27C138.44 15.12 137.39 16.8 136.16 18.33C134.93 19.85 133.54 21.2 131.99 22.37L127.64 18.4C130.51 16.37 132.67 13.79 134.14 10.66C135.61 7.52 136.34 3.97 136.34 0ZM152.67 40.34H140.07C137 40.34 134.57 39.64 132.8 38.24C131.03 36.84 130.14 34.8 130.14 32.12C130.14 29.71 131.03 27.74 132.8 26.2C134.57 24.66 136.99 23.89 140.07 23.89H152.67C155.68 23.89 158.08 24.66 159.87 26.2C161.65 27.74 162.55 29.71 162.55 32.12C162.55 34.8 161.66 36.84 159.87 38.24C158.08 39.64 155.68 40.34 152.67 40.34ZM140.07 35.16H152.67C153.81 35.16 154.75 34.88 155.48 34.33C156.21 33.78 156.58 33.04 156.58 32.11C156.58 31.18 156.21 30.48 155.48 29.91C154.75 29.34 153.81 29.05 152.67 29.05H140.07C138.84 29.05 137.88 29.34 137.19 29.91C136.5 30.48 136.16 31.21 136.16 32.11C136.16 33.01 136.5 33.77 137.19 34.33C137.88 34.89 138.84 35.16 140.07 35.16ZM166.67 7.85V13.67H161.75V22.63H155.91V0H161.75V7.85H166.67Z" fill="white"/>
            <path d="M195.6 0.65H213.95V3.75C213.95 6.25 213.5 8.55 212.59 10.66C211.68 12.77 210.42 14.61 208.81 16.18C207.2 17.75 205.3 19.04 203.1 20.04C200.9 21.04 198.53 21.68 195.99 21.96C195.79 21.56 195.59 21.15 195.4 20.74C195.21 20.32 195.03 19.92 194.85 19.51C194.67 19.08 194.49 18.65 194.3 18.24C194.11 17.82 193.93 17.4 193.75 16.97C197.55 16.54 200.75 15.39 203.34 13.53C205.93 11.67 207.5 9.16 208.06 6.02H195.59V0.65H195.6ZM228.4 23.8V39.7H196.84V23.8H228.4ZM202.67 34.56H222.6V28.92H202.67V34.56ZM228.4 0V22H222.61V14.28H214.36V8.69H222.61V0H228.4Z" fill="white"/>
            <path d="M260 18.3H296.31V23.29H260V18.3ZM294.46 0.650024V5.64002H282.12C281.92 5.98002 281.7 6.30002 281.48 6.61002C281.26 6.92002 281.03 7.21002 280.8 7.49002C282.85 8.78002 285.19 9.81003 287.82 10.56C290.45 11.32 293.15 11.79 295.9 11.97L293.66 16.54C290.85 16.26 287.96 15.65 284.99 14.69C282.02 13.73 279.44 12.5 277.24 10.99C275.19 12.65 272.91 14.02 270.39 15.08C267.87 16.14 265.39 16.81 262.93 17.09L260.43 12.61C263.33 12.27 266.07 11.51 268.66 10.32C271.25 9.13002 273.48 7.57002 275.36 5.63002H261.84V0.650024H294.46ZM294.86 33.04C294.86 35.41 294 37.22 292.29 38.47C290.58 39.72 288.21 40.34 285.2 40.34H271.15C268.08 40.34 265.69 39.72 263.99 38.47C262.29 37.22 261.44 35.41 261.44 33.04C261.44 30.67 262.29 28.97 263.99 27.66C265.69 26.35 268.07 25.7 271.15 25.7H285.2C288.21 25.7 290.58 26.36 292.29 27.66C294 28.97 294.86 30.76 294.86 33.04ZM288.89 33.04C288.89 32.3 288.58 31.7 287.95 31.24C287.32 30.78 286.3 30.55 284.9 30.55H271.42C270.1 30.55 269.11 30.78 268.44 31.24C267.77 31.7 267.43 32.3 267.43 33.04C267.43 33.78 267.74 34.37 268.37 34.82C269 35.27 270.02 35.49 271.42 35.49H284.9C286.31 35.49 287.32 35.27 287.95 34.82C288.58 34.37 288.89 33.78 288.89 33.04Z" fill="white"/>
            <path d="M362.16 24.07V29.75H347.1V40.33H340.91V29.75H325.85V24.07H362.16ZM360.31 21.49H354.47V6.28002H327.69V0.650024H360.31V21.49Z" fill="white"/>
            <path d="M392.14 11.83V10.77C392.14 9.23 392.4 7.8 392.91 6.47C393.42 5.14 394.13 4.01 395.04 3.05C395.98 2.1 397.09 1.35 398.38 0.81C399.66 0.27 401.08 0 402.63 0C404.18 0 405.56 0.27 406.84 0.81C408.12 1.35 409.24 2.1 410.18 3.05C411.12 4.01 411.85 5.15 412.38 6.47C412.91 7.79 413.17 9.23 413.17 10.77V11.83C413.17 13.4 412.91 14.84 412.38 16.15C411.85 17.46 411.12 18.59 410.18 19.55C409.24 20.51 408.13 21.24 406.84 21.77C405.55 22.3 404.15 22.55 402.63 22.55C401.11 22.55 399.66 22.29 398.37 21.77C397.08 21.25 395.97 20.51 395.03 19.55C394.12 18.6 393.41 17.46 392.9 16.15C392.39 14.84 392.13 13.4 392.13 11.83H392.14ZM425.94 25.74V40.34H420.15V31.24H393.99V25.74H425.95H425.94ZM397.89 10.77V11.83C397.89 13.4 398.31 14.73 399.14 15.8C399.97 16.88 401.14 17.42 402.63 17.42C404.12 17.42 405.25 16.88 406.1 15.8C406.95 14.72 407.37 13.4 407.37 11.83V10.77C407.37 9.2 406.95 7.87 406.1 6.79C405.25 5.71 404.1 5.17 402.63 5.17C401.16 5.17 399.97 5.71 399.14 6.79C398.31 7.87 397.89 9.2 397.89 10.77ZM420.15 3.88V0H425.94V23.89H420.15V18.99H414.27V13.58H420.15V9.33H414.27V3.88H420.15Z" fill="white"/>
            <path d="M443.5 13.03H436.83V6.28003H443.5V13.03ZM443.5 32.99H436.83V26.24H443.5V32.99Z" fill="white"/>
          </svg>

        </div>
      </div>
      <div v-if="isQuestionLoading" class="loading-screen">
        <LoadingSymbol/>
        <span style="color: white; font-size: 16px; font-weight: bold;">
          질문을 불러오는 중입니다
        </span>
        <div class="loading-logo">
          <svg width="80" height="44" viewBox="0 0 505 227" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.33 89.04H51.04V69.91H0.669983V225.31H51.04V206.18H21.33V89.04Z" fill="white"/>
            <path d="M67.43 125.92H51.04V144.88H67.43V225.31H88.1V69.91H67.43V125.92Z" fill="white"/>
            <path d="M504.67 69.91H484.01V225.31H504.67V69.91Z" fill="white"/>
            <path d="M438.08 89.04H467.79V69.91H417.42V225.31H467.79V206.18H438.08V151.71H467.96V132.58H438.08V89.04Z" fill="white"/>
            <path d="M189.91 125.92H178.64V144.88H189.91V225.31H210.57V69.91H189.91V125.92Z" fill="white"/>
            <path d="M122.91 69.97V90.15C139.49 90.15 152.98 102.75 152.98 118.25L153 132.92H122.92V152.05H153.02L153.1 225.32L173.28 225.3L173.17 118.25C173.17 91.63 150.63 69.97 122.92 69.97H122.91Z" fill="white"/>
            <path d="M404.74 69.91H384.08V225.31H404.74V69.91Z" fill="white"/>
            <path d="M341.13 176.96L341.02 69.9L320.84 69.92L320.95 176.97C320.95 203.59 343.49 225.25 371.2 225.25V205.07C354.62 205.07 341.13 192.46 341.13 176.96Z" fill="white"/>
            <path d="M225.89 188.34H238.18V226.08H258.84V188.34H272.34V226.08H293V188.34H306.15V169.22H225.89V188.34Z" fill="white"/>
            <path d="M304.67 160.25V108.88C304.67 108.37 304.61 107.87 304.59 107.36V70.69C304.59 70.69 228.44 70.69 228.2 70.69V108.7C228.2 108.7 228.2 108.77 228.2 108.81V110.23L228.28 110.17V160.25C228.28 160.25 304.43 160.25 304.67 160.25ZM283.81 139.39H248.94V91.21H283.81V139.39Z" fill="white"/>
            <path d="M67.19 38.72L61.83 36.04C64.64 30.74 66.57 25.06 67.63 18.99C68.68 12.92 69.21 6.73 69.21 0.41H75.05C75.05 2.63 74.99 4.86 74.87 7.11C74.75 9.36 74.56 11.61 74.3 13.86C74.77 15.74 75.37 17.63 76.1 19.52C76.83 21.41 77.68 23.24 78.65 25C79.59 26.79 80.63 28.49 81.79 30.11C82.95 31.73 84.18 33.18 85.5 34.48L81.11 38.41C79.32 36.56 77.69 34.44 76.22 32.06C74.74 29.67 73.48 27.19 72.42 24.6C71.81 27.1 71.07 29.54 70.2 31.92C69.34 34.31 68.33 36.58 67.19 38.74V38.72ZM95.91 20.89V39.93H90.07V0H95.91V15.02H100.83V20.89H95.91Z" fill="white"/>
            <path d="M136.34 0H142.18C142.18 1.23 142.11 2.45 141.98 3.65C141.85 4.85 141.67 6.01 141.43 7.12C142.66 9.06 144.22 10.9 146.13 12.62C148.03 14.35 149.99 15.76 152.01 16.87L148.54 21.4C146.87 20.41 145.23 19.21 143.62 17.8C142.01 16.38 140.57 14.87 139.32 13.27C138.44 15.12 137.39 16.8 136.16 18.33C134.93 19.85 133.54 21.2 131.99 22.37L127.64 18.4C130.51 16.37 132.67 13.79 134.14 10.66C135.61 7.52 136.34 3.97 136.34 0ZM152.67 40.34H140.07C137 40.34 134.57 39.64 132.8 38.24C131.03 36.84 130.14 34.8 130.14 32.12C130.14 29.71 131.03 27.74 132.8 26.2C134.57 24.66 136.99 23.89 140.07 23.89H152.67C155.68 23.89 158.08 24.66 159.87 26.2C161.65 27.74 162.55 29.71 162.55 32.12C162.55 34.8 161.66 36.84 159.87 38.24C158.08 39.64 155.68 40.34 152.67 40.34ZM140.07 35.16H152.67C153.81 35.16 154.75 34.88 155.48 34.33C156.21 33.78 156.58 33.04 156.58 32.11C156.58 31.18 156.21 30.48 155.48 29.91C154.75 29.34 153.81 29.05 152.67 29.05H140.07C138.84 29.05 137.88 29.34 137.19 29.91C136.5 30.48 136.16 31.21 136.16 32.11C136.16 33.01 136.5 33.77 137.19 34.33C137.88 34.89 138.84 35.16 140.07 35.16ZM166.67 7.85V13.67H161.75V22.63H155.91V0H161.75V7.85H166.67Z" fill="white"/>
            <path d="M195.6 0.65H213.95V3.75C213.95 6.25 213.5 8.55 212.59 10.66C211.68 12.77 210.42 14.61 208.81 16.18C207.2 17.75 205.3 19.04 203.1 20.04C200.9 21.04 198.53 21.68 195.99 21.96C195.79 21.56 195.59 21.15 195.4 20.74C195.21 20.32 195.03 19.92 194.85 19.51C194.67 19.08 194.49 18.65 194.3 18.24C194.11 17.82 193.93 17.4 193.75 16.97C197.55 16.54 200.75 15.39 203.34 13.53C205.93 11.67 207.5 9.16 208.06 6.02H195.59V0.65H195.6ZM228.4 23.8V39.7H196.84V23.8H228.4ZM202.67 34.56H222.6V28.92H202.67V34.56ZM228.4 0V22H222.61V14.28H214.36V8.69H222.61V0H228.4Z" fill="white"/>
            <path d="M260 18.3H296.31V23.29H260V18.3ZM294.46 0.650024V5.64002H282.12C281.92 5.98002 281.7 6.30002 281.48 6.61002C281.26 6.92002 281.03 7.21002 280.8 7.49002C282.85 8.78002 285.19 9.81003 287.82 10.56C290.45 11.32 293.15 11.79 295.9 11.97L293.66 16.54C290.85 16.26 287.96 15.65 284.99 14.69C282.02 13.73 279.44 12.5 277.24 10.99C275.19 12.65 272.91 14.02 270.39 15.08C267.87 16.14 265.39 16.81 262.93 17.09L260.43 12.61C263.33 12.27 266.07 11.51 268.66 10.32C271.25 9.13002 273.48 7.57002 275.36 5.63002H261.84V0.650024H294.46ZM294.86 33.04C294.86 35.41 294 37.22 292.29 38.47C290.58 39.72 288.21 40.34 285.2 40.34H271.15C268.08 40.34 265.69 39.72 263.99 38.47C262.29 37.22 261.44 35.41 261.44 33.04C261.44 30.67 262.29 28.97 263.99 27.66C265.69 26.35 268.07 25.7 271.15 25.7H285.2C288.21 25.7 290.58 26.36 292.29 27.66C294 28.97 294.86 30.76 294.86 33.04ZM288.89 33.04C288.89 32.3 288.58 31.7 287.95 31.24C287.32 30.78 286.3 30.55 284.9 30.55H271.42C270.1 30.55 269.11 30.78 268.44 31.24C267.77 31.7 267.43 32.3 267.43 33.04C267.43 33.78 267.74 34.37 268.37 34.82C269 35.27 270.02 35.49 271.42 35.49H284.9C286.31 35.49 287.32 35.27 287.95 34.82C288.58 34.37 288.89 33.78 288.89 33.04Z" fill="white"/>
            <path d="M362.16 24.07V29.75H347.1V40.33H340.91V29.75H325.85V24.07H362.16ZM360.31 21.49H354.47V6.28002H327.69V0.650024H360.31V21.49Z" fill="white"/>
            <path d="M392.14 11.83V10.77C392.14 9.23 392.4 7.8 392.91 6.47C393.42 5.14 394.13 4.01 395.04 3.05C395.98 2.1 397.09 1.35 398.38 0.81C399.66 0.27 401.08 0 402.63 0C404.18 0 405.56 0.27 406.84 0.81C408.12 1.35 409.24 2.1 410.18 3.05C411.12 4.01 411.85 5.15 412.38 6.47C412.91 7.79 413.17 9.23 413.17 10.77V11.83C413.17 13.4 412.91 14.84 412.38 16.15C411.85 17.46 411.12 18.59 410.18 19.55C409.24 20.51 408.13 21.24 406.84 21.77C405.55 22.3 404.15 22.55 402.63 22.55C401.11 22.55 399.66 22.29 398.37 21.77C397.08 21.25 395.97 20.51 395.03 19.55C394.12 18.6 393.41 17.46 392.9 16.15C392.39 14.84 392.13 13.4 392.13 11.83H392.14ZM425.94 25.74V40.34H420.15V31.24H393.99V25.74H425.95H425.94ZM397.89 10.77V11.83C397.89 13.4 398.31 14.73 399.14 15.8C399.97 16.88 401.14 17.42 402.63 17.42C404.12 17.42 405.25 16.88 406.1 15.8C406.95 14.72 407.37 13.4 407.37 11.83V10.77C407.37 9.2 406.95 7.87 406.1 6.79C405.25 5.71 404.1 5.17 402.63 5.17C401.16 5.17 399.97 5.71 399.14 6.79C398.31 7.87 397.89 9.2 397.89 10.77ZM420.15 3.88V0H425.94V23.89H420.15V18.99H414.27V13.58H420.15V9.33H414.27V3.88H420.15Z" fill="white"/>
            <path d="M443.5 13.03H436.83V6.28003H443.5V13.03ZM443.5 32.99H436.83V26.24H443.5V32.99Z" fill="white"/>
          </svg>

        </div>
      </div>

      <div class="question-container" v-for="(question, index) in activeQuestions" :key="question.id">
        <div v-if="index <= currentQuestionIndex">
          <span class="questions">{{ question.content }}</span>
          <div class="answers">
            <label v-for="score in getScoreRange(question.questiontheme)" :key="score.value">
              <input type="radio" :id="'question' + question.id + '-' + score.value" class="radio-custom" :name="'question' + question.id" :value="score.value" @change="answerQuestion(question.id, score.value)">
              <label :for="'question' + question.id + '-' + score.value" :style="{ width: score.size, height: score.size }"></label>
            </label>
          </div>
          <div v-if="question.questiontheme !== 'class'" class="question-label">
            <div class="question-label-text">매우 반대</div>
            <div class="question-label-text" style="transform:translateX(15px)">약간 반대</div>
            <div class="question-label-text" style="transform:translateX(-12px)">약간 동의</div>
            <div class="question-label-text">강하게 동의</div>
          </div>

          <!-- 질문 테마가 'class'인 경우에 대한 레이블 -->
          <div v-else class="question-label">
            <div class="question-label-text">전혀 아니다</div>
            <div class="question-label-text">가끔 그렇다</div>
            <div class="question-label-text">종종 그렇다</div>
            <div class="question-label-text">항상 그렇다</div>
          </div>
          <div :ref="setQuestionRef"/>
        </div>
      </div>
      <div class="blank"/>
      <button :class="['submit-button', { disabled: !allAnswered }]" @click="submitSurvey">
        <div class="submit-progress" :style="{ width: submitButtonWidth }"></div>
        <span class="submit-button-text">{{ submitButtonText }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import supabase from '@/helper/supabaseClient.js'
import LoadingSymbol from '@/components/LoadingSymbol.vue'

export default {
  name: 'HelloWorld',
  components: {
    LoadingSymbol
  },
  data() {
    return {
      questions: [],
      answers: {}, // 객체로 변경하여 각 질문 ID에 대한 응답을 저장합니다.
      currentQuestionIndex: 0,
      questionRefs: [],
      nickname: '',
      userReferralCode: '',
      isLoading: false,
      isQuestionLoading: true,
    }
  },
  async mounted() {
    window.scrollTo(0, 0);
    this.nickname = this.$route.query.nickname;
    const { data, error } = await supabase
        .from('questions')
        .select('*');
    if (error) {
      console.log(error);
    } else {
      this.isQuestionLoading = false;
      this.questions = this.organizeQuestions(data); // 변경된 메소드를 호출하여 질문들을 조직합니다.
    }
  },
  methods: {
    shuffleArray(array) {
      return array.sort(() => Math.random() - 0.5);
    },
    organizeQuestions(questions) {
      // 'class'가 아닌 질문들과 'class'인 질문들을 분리합니다.
      const nonClassQuestions = questions.filter(q => q.questiontheme !== 'class');
      const classQuestions = questions.filter(q => q.questiontheme === 'class');

      // 각각의 배열을 셔플합니다.
      const shuffledNonClassQuestions = this.shuffleArray(nonClassQuestions);
      const shuffledClassQuestions = this.shuffleArray(classQuestions);

      // 셔플된 배열들을 합쳐서 반환합니다.
      return [...shuffledNonClassQuestions, ...shuffledClassQuestions];
    },
    setQuestionRef(el) {
      if (el) {
        this.questionRefs.push(el);
      }
    },
    answerQuestion(questionId, score) {
      this.answers[questionId] = score;
      if (this.questions[this.currentQuestionIndex].id === questionId) {
        this.currentQuestionIndex++;
        this.$nextTick(() => {
          this.scrollToNextQuestion();
        });      }
    },

    scrollToNextQuestion() {
      // .screen 요소의 참조를 가져옵니다.
      const screenElement = this.$el.querySelector('.screen');
      if (screenElement) {
        const currentScrollPosition = screenElement.scrollTop;
        const scrollDistance = 1000; // 상대적으로 이동할 거리 (예: 200px)

        // 스크롤 이동
        screenElement.scrollTo({
          top: currentScrollPosition + scrollDistance,
          behavior: 'smooth'
        });
      }
    },

    getScoreRange(questiontheme) {
      // 질문 테마에 따라 점수 범위를 정의합니다.
      // 여기서 라디오 버튼 크기를 조정할 수 있습니다.
      if (questiontheme === 'class') {
        return [1, 2, 3, 4].map((score, index, array) => ({
          value: score,
          size: this.calculateButtonSize(index, array.length)
        }));
      } else {
        return [1, 2, 3, 4, 5, 6].map((score, index, array) => ({
          value: score,
          size: this.calculateButtonSize(index, array.length)
        }));
      }
    },

    calculateButtonSize(index, length) {
      // 첫 번째와 마지막 버튼은 크게, 나머지는 작게 설정
      if (index === 0 || index === length - 1) {
        return '45px';
      }
      return '36px';
    },
    async submitSurvey() {
      if (!this.allAnswered) {
        alert('모든 질문에 응답해주세요');
        return;
      }
      this.isLoading = true;
      const newUser = await this.createUser();
      if (newUser) {
        console.log('Submitting responses for user:', newUser.id);
        const success = await this.submitResponses(newUser.id);
        if (success) {
          this.isLoading = false;
          this.$router.push({ path: '/result', query: { referral: this.userReferralCode } });
        } else {
          console.warn('Failed to submit responses');
          this.isLoading = false;
          alert('이용자가 많습니다. 잠시 후 다시 시도해주세요.')
        }
      } else {
        console.warn('Failed to create user');
      }
    },
    async createUser() {
      const { data, error } = await supabase
          .from('users')
          .insert([{ nickname: this.nickname }])
          .select();

      if (error) {
        console.error('Error creating new user:', error);
        return null; // 오류 발생 시 null 반환
      }

      if (!data || data.length === 0) {
        console.error('No data returned when creating user');
        return null; // 데이터가 없거나 비어있는 경우 null 반환
      }

      this.userReferralCode = data[0].referralcode; // 생성된 유저의 리퍼럴 코드를 저장
      return data[0]; // 성공적으로 생성된 사용자 데이터 반환
    },
    async submitResponses(userId) {
      // 각 질문에 대한 응답을 준비합니다.
      const responses = this.questions.map(question => ({
        userid: userId,
        questionid: question.id,
        questiontheme: question.questiontheme,
        response: this.answers[question.id]
      }));

      // Supabase에 응답을 삽입합니다.
      const { error } = await supabase
          .from('responses')
          .insert(responses);

      // 오류가 발생한 경우 콘솔에 오류를 출력합니다.
      if (error) {
        console.error('Error submitting responses:', error);
        return false;
      }
      return true;
    }
  },
  computed: {
    allAnswered() {
      return Object.keys(this.answers).length === this.questions.length;
    },
    submitButtonWidth() {
      const totalQuestions = this.questions.length;
      const answeredQuestions = Object.keys(this.answers).length;
      // 최소 너비를 2%로 설정
      const minWidth = 2;
      const width = (answeredQuestions / totalQuestions) * 100;
      return Math.max(minWidth, width) + '%';
    },
    submitButtonText() {
      return this.allAnswered ? '제출하기' : '완료하고 제출하기';
    },
    activeQuestions() {
      return this.questions.slice(0, this.currentQuestionIndex + 1);
    },
  }
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column; /* 컬럼 방향으로 정렬 */
  justify-content: flex-start; /* 상단부터 시작 */
  align-items: center; /* 가운데 정렬 (좌우) */
  width: 100vw;
  height: 100vh;
  max-width: 480px;
  max-height: 2160px;
  overflow: hidden;
  background-color: #FCFCFC;
  margin: 0 auto
}

.screen {
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center; /* 내부 요소 좌우 중앙 정렬 */
}

.loading-screen{
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.9);
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.loading-logo{
  position: absolute;
  bottom: 30px;
}

.question-container {
  position: relative;
  width: 70%;
  align-self: center; /* 좌우 중앙 정렬 */
  margin-bottom: 45px; /* 하단 여백 추가 */
}

.question-container:first-child {
  margin-top: 60px; /* 상단 여백 추가 */
}


.questions{
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  word-break: keep-all;
}
.answers {
  display: flex;
  justify-content: space-between;
  width: 100%; /* 질문과 동일한 너비 */
  align-items: center;
  position: relative;
  padding: 30px 0; /* 상단 및 하단 여백 추가 */
}
.answers::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* 가운데 정렬 */
  width: calc(100% - 45px); /* 질문과 동일한 너비 */
  height: 0.5px;
  background-color: #929292;
  z-index: 1;
}

/* 라디오 버튼 커스텀 스타일 */
.radio-custom {
  display: none;
}

.radio-custom + label {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid #929292;
  border-radius: 50%;
  cursor: pointer;
  width: 40px;
  height: 40px;
  position: relative;
  gap: 20px;
  transition: background-color 1s;
}

.radio-custom + label::after {
  content: '';
  background-color: #929292;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  z-index: 2;
}

/* 라디오 버튼 선택 시 */
.radio-custom:checked + label::before {
  background-color: black; /* 검정색 배경 */
  background-image: url('@/assets/check.svg'); /* 체크 아이콘 */
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px 11px;
  z-index: 3;
}

/* 라디오 버튼 라벨의 크기 조정 */
.radio-custom + label::before {
  content: '';
  background-color: #fafafa; /* 초기 상태를 흰색으로 설정 */
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
}

/* 각 끝단 라디오 버튼 크기 조정 */
.radio-custom:first-of-type + label, .radio-custom:last-of-type + label {
  width: 45px;
  height: 45px;
}

.question-label{
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
}

.question-label-text{
  font-size: 10px;
  line-height: 0px;
  color: #929292;
}


.submit-button {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #cecece; /* 회색 배경 */
  width: 100%;
  height: 60px;
  color: white;
  overflow: hidden;
  z-index: 5
}

.submit-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  background-color: black; /* 프로그레스바 색상 */
  width: 0%;
  z-index: 6; /* 텍스트 뒤에 위치 */
}

.submit-button-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: bold;
  z-index: 7; /* 프로그레스 바 위에 위치 */
}

.blank{
  display: flex;
  height: 60px;
  width: 50px;
  margin-bottom: 30px;
}


</style>
