import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from "vue-router";
import { createVueKakaoSdk } from 'vue3-kakao-sdk'

import FormPage from '@/components/FormPage.vue'
import EntryPage from "@/components/EntryPage";
import ResultPage from "@/components/ResultPage";
import SymbolPage from "@/components/SymbolPage";
import HomePage from "@/components/HomePage";

const routes = [
    { path: "/", redirect: '/home' },
    { path: "/home", component: HomePage},
    { path: "/intro", component: EntryPage },
    { path: "/start", component: FormPage },
    { path: "/result", component: ResultPage },
    { path: "/symbol", component: SymbolPage},

];

const router = createRouter({
    history: createWebHistory('/'),
    routes: routes,
});

router.beforeEach((to, from, next) => {
    to.meta.fromPath = from.fullPath;
    next();
});

const app = createApp(App);
app.use(router);
app.use(createVueKakaoSdk('8bb7aea2b5bd0919a5adb3f511785f5d'))


app.mount('#app');
