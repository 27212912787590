<template>
  <div class="container">
    <div class="screen">
      <div class="logo">
        <div v-show="!isImageLoaded" class="loading"/>
        <img v-show="isImageLoaded" src="@/assets/the-community-long-banner.jpg" @load="loadComplete" alt="더 커뮤니티 배너" width="100%"/>
      </div>
      <div class="nickname-area">
        <h2>닉네임을 입력해주세요</h2>
        <input v-model="nickname" type="text" maxlength="8" minlength="2" :placeholder="randomPlaceholder"/>
      </div>
      <span class="nickname-guide">닉네임은 최소 2글자, 최대 8글자 작성 가능</span>

      <div class="disclaimer">
        <div class="disclaimer-text">
          <p>※ 본 테스트는 웨이브 오리지널 [사상검증구역: 더 커뮤니티]의 프로그램 내에서 활용하기 위해 제작된 척도로, <strong>측정값이 응답자의 정치사회적 견해와 일부 일치하지 않을 수 있습니다.</strong></p>
        </div>
        <div class="disclaimer-text">
          <p>※ 테스트의 질문들은 연세대학교 사회과학대학 김용찬 교수의 자문을 거쳐 작성되었으며, 리서치 업체 '엠브레인'을 통해 신뢰도 조사를 완료했습니다.</p>
        </div>
        <div class="disclaimer-text">
          <p>※ 실제로 존재하는 여러 사회적 입장에 대한 응답자의 ‘동의 여부’를 확인하는 것이므로, 일부 차별적이거나 과격하다고 느껴지는 문항이 있는 점을 참고하여 주시기 바랍니다.</p>
        </div>
      </div>
    </div>
    <div class="submit-button" :class="{active: isButtonActive}" @click="submitName">닉네임 입력하고 시작하기</div>
  </div>
</template>

<script>
export default {
  name: "EntryPage",
  data() {
    return {
      nickname: '',
      randomPlaceholder: '',
      placeholderNames: ['제이미', '니콜', '애슐리', '재키', '라이오넬', '김반장', "피카츄"],
      isImageLoaded: false,
    }
  },
  mounted() {
    window.scrollTo(0, 0);

    this.randomPlaceholder = this.placeholderNames[Math.floor(Math.random() * this.placeholderNames.length)];

  },
  computed: {
    isButtonActive() {
      return this.nickname.length >= 2;
    }
  },
  methods: {
    submitName() {
      if (this.nickname.trim() !== '') {
        this.$router.push({ path: '/start', query: { nickname: this.nickname } });
      } else {
        alert('닉네임을 입력해주세요');
      }
    },
    loadComplete() {
      this.isImageLoaded = true;
    }
  }
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100vw;
  height: 100vh;
  align-items: start;
  min-height: 100vh;
  max-width: 480px;
  max-height: 2160px;
  margin: 0 auto;
  background-color: #FCFCFC;
}

.screen {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background-color: #FCFCFC;
}

.logo{
  margin-bottom: 20px;
}

.loading {
  width: 100%;
  height: 150px;
}

h2{
  font-size: 20px;
  font-weight: 600;
}

.nickname-area input {
  width: 70%; /* 입력 필드 너비 */
  padding: 5px 10px; /* 패딩 */
  font-size: 36px; /* 폰트 크기 */
  font-weight: bold;
  border-bottom: 2px solid #000000;
  text-align: center;
}

.nickname-area input::placeholder {
  color: #c0c0c0; /* 플레이스홀더 색상 */
}

.nickname-area input:focus {
  outline: none; /* 포커스 시 아웃라인 제거 */
}

.submit-button{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 480px;
  bottom: 0;
  height: 60px;

  background-color: #cecece;
  color: white;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

.submit-button.active {
  background-color: #111111; /* 활성화 시 배경색 변경 */
}

.nickname-guide{
  font-size: 12px;
  color: #707070;
  margin-top: 10px;
}

.disclaimer{
  margin-top: 20px;
  display: flex;
  width: 72%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.disclaimer-text{
  margin-bottom: 10px;
  font-size: 12px;
  color: #707070;
  line-height: 1.5;
}



</style>