<template>
  <svg width="100" height="213" viewBox="0 0 197 276" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="classColor" d="M98.55 120.97H98.53H0.73999V198.4H98.53H98.55H196.36V120.97H98.55Z" :fill="classColor"/>
    <g style="mix-blend-mode:multiply" opacity="0.5">
      <path d="M0.73999 198.3H196.34V192.44V121.06" fill="black"/>
    </g>
    <path class="opennessColor" d="M196.36 275.64V198.2L0.73999 198.22V275.64H196.36Z" :fill="opennessColor"/>
    <path opacity="0.5" d="M196.36 275.64H98.54V249.57H131.96V224.95H164.13V198.2H196.36" fill="black"/>
    <path opacity="0.5" d="M49.64 266.72C66.0981 266.72 79.4399 253.378 79.4399 236.92C79.4399 220.462 66.0981 207.12 49.64 207.12C33.1819 207.12 19.84 220.462 19.84 236.92C19.84 253.378 33.1819 266.72 49.64 266.72Z" fill="black"/>
    <path class="politicsColor" d="M98.55 0C98.55 0 98.53 0 98.51 0C44.51 0.02 0.75 43.8 0.75 97.8H196.35C196.35 43.79 152.56 0 98.55 0Z" :fill="politicsColor"/>
    <g opacity="0.5">
      <mask id="mask0_1_5" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="197" height="98">
        <path d="M196.48 97.8H0.630007C0.620007 43.8 44.39 0.0200006 98.38 5.52894e-07C98.39 5.52894e-07 98.4 5.52894e-07 98.42 5.52894e-07C98.42 5.52894e-07 190.23 -0.110001 196.48 97.8Z" fill="white"/>
      </mask>
      <g mask="url(#mask0_1_5)">
        <path class="politicsColor2" d="M99.2 5.07999H97.64V83.85H99.2V5.07999Z" :fill="politicsColor2"/>
        <path class="politicsColor2" d="M100.76 83.16L98.42 87.22L96.08 83.16H100.76Z" :fill="politicsColor2"/>
        <path class="politicsColor2" d="M82.8869 6.36938L81.3507 6.64026L95.0133 84.1249L96.5496 83.854L82.8869 6.36938Z" :fill="politicsColor2"/>
        <path class="politicsColor2" d="M97.97 82.91L96.37 87.31L93.36 83.72L97.97 82.91Z" :fill="politicsColor2"/>
        <path class="politicsColor2" d="M67.045 10.4712L65.579 11.0048L92.5165 85.015L93.9825 84.4814L67.045 10.4712Z" :fill="politicsColor2"/>
        <path class="politicsColor2" d="M95.21 83.31L94.4 87.92L90.8199 84.92L95.21 83.31Z" :fill="politicsColor2"/>
        <path class="politicsColor2" d="M52.1542 17.275L50.8031 18.0551L90.1581 86.2199L91.5092 85.4399L52.1542 17.275Z" :fill="politicsColor2"/>
        <path class="politicsColor2" d="M92.52 84.06V88.74L88.46 86.4L92.52 84.06Z" :fill="politicsColor2"/>
        <path class="politicsColor2" d="M38.678 26.5518L37.4829 27.5546L88.096 87.873L89.2911 86.8702L38.678 26.5518Z" :fill="politicsColor2"/>
        <path class="politicsColor2" d="M90.04 85.34L90.85 89.95L86.45 88.35L90.04 85.34Z" :fill="politicsColor2"/>
        <path class="politicsColor2" d="M26.9976 38.02L25.9949 39.215L86.3362 89.8474L87.3389 88.6524L26.9976 38.02Z" :fill="politicsColor2"/>
        <path class="politicsColor2" d="M87.8199 87.02L89.42 91.42L84.8101 90.6L87.8199 87.02Z" :fill="politicsColor2"/>
        <path class="politicsColor2" d="M17.5109 51.3445L16.726 52.7041L84.8995 92.0641L85.6844 90.7045L17.5109 51.3445Z" :fill="politicsColor2"/>
        <path class="politicsColor2" d="M85.86 89.01L88.2 93.07H83.52L85.86 89.01Z" :fill="politicsColor2"/>
        <path class="politicsColor2" d="M10.4625 66.1359L9.92896 67.6019L83.8452 94.5052L84.3788 93.0392L10.4625 66.1359Z" :fill="politicsColor2"/>
        <path class="politicsColor2" d="M84.27 91.33L87.28 94.91L82.67 95.73L84.27 91.33Z" :fill="politicsColor2"/>
        <path class="politicsColor2" d="M6.09669 81.8956L5.82581 83.4319L83.3203 97.0962L83.5912 95.56L6.09669 81.8956Z" :fill="politicsColor2"/>
        <path class="politicsColor2" d="M83.1899 93.91L86.77 96.91L82.37 98.52L83.1899 93.91Z" :fill="politicsColor2"/>
        <path class="politicsColor2" d="M99.36 5.07999H97.8V83.85H99.36V5.07999Z" :fill="politicsColor2"/>
        <path class="politicsColor2" d="M100.92 83.16L98.58 87.22L96.24 83.16H100.92Z" :fill="politicsColor2"/>
        <path class="politicsColor2" d="M114.132 6.37001L100.456 83.8524L101.993 84.1235L115.669 6.64117L114.132 6.37001Z" :fill="politicsColor2"/>
        <path class="politicsColor2" d="M103.65 83.72L100.64 87.31L99.04 82.91L103.65 83.72Z" :fill="politicsColor2"/>
        <path class="politicsColor2" d="M129.997 10.5017L103.073 84.5166L104.539 85.0499L131.463 11.035L129.997 10.5017Z" :fill="politicsColor2"/>
        <path class="politicsColor2" d="M106.19 84.92L102.6 87.92L101.79 83.31L106.19 84.92Z" :fill="politicsColor2"/>
        <path class="politicsColor2" d="M144.818 17.2381L105.463 85.403L106.814 86.183L146.169 18.0181L144.818 17.2381Z" :fill="politicsColor2"/>
        <path class="politicsColor2" d="M108.54 86.4L104.49 88.74V84.06L108.54 86.4Z" :fill="politicsColor2"/>
        <path class="politicsColor2" d="M158.361 26.5628L107.758 86.89L108.953 87.8925L159.556 27.5653L158.361 26.5628Z" :fill="politicsColor2"/>
        <path class="politicsColor2" d="M110.55 88.35L106.15 89.95L106.97 85.34L110.55 88.35Z" :fill="politicsColor2"/>
        <path class="politicsColor2" d="M169.987 38.0008L109.645 88.6332L110.648 89.8282L170.989 39.1958L169.987 38.0008Z" :fill="politicsColor2"/>
        <path class="politicsColor2" d="M112.19 90.6L107.58 91.42L109.18 87.02L112.19 90.6Z" :fill="politicsColor2"/>
        <path class="politicsColor2" d="M179.559 51.459L111.379 90.8072L112.164 92.167L180.344 52.8188L179.559 51.459Z" :fill="politicsColor2"/>
        <path class="politicsColor2" d="M113.48 93.07H108.8L111.14 89.02L113.48 93.07Z" :fill="politicsColor2"/>
        <path class="politicsColor2" d="M186.505 66.0153L112.593 92.9315L113.127 94.3973L187.039 67.4811L186.505 66.0153Z" :fill="politicsColor2"/>
        <path class="politicsColor2" d="M114.33 95.73L109.72 94.91L112.73 91.33L114.33 95.73Z" :fill="politicsColor2"/>
        <path class="politicsColor2" d="M190.899 81.7948L113.404 95.4592L113.675 96.9955L191.169 83.3311L190.899 81.7948Z" :fill="politicsColor2"/>
        <path class="politicsColor2" d="M114.63 98.52L110.23 96.91L113.82 93.91L114.63 98.52Z" :fill="politicsColor2"/>
      </g>
    </g>
    <path class="genderColor" d="M196.37 97.77H0.73999V120.92H196.37V97.77Z" :fill="genderColor"/>
  </svg>
</template>



<script>
export default {
  name: "LoadingSymbol",
  data() {
    return {
      currentStatus: 'L1F1W1O1'
    }
  },
  computed: {
    politicsStatus() {
      return this.currentStatus.substring(0, 2);
    },
    genderStatus() {
      return this.currentStatus.substring(2, 4);
    },
    classStatus() {
      return this.currentStatus.substring(4, 6);
    },
    opennessStatus() {
      return this.currentStatus.substring(6, 8);
    },
    politicsColor() {
      switch (this.politicsStatus){
      case 'L1':
        return '#FF7719';
      case 'L2':
        return '#E38859';
      case 'L3':
        return '#B69687';
      case 'R4':
        return '#507C7E';
      case 'R5':
        return '#009095';
      case 'R6':
        return '#00A3AA';
      default:
        return '#B2B2B2';
      }
    },
    politicsColor2() {
      switch (this.politicsStatus){
        case 'L1':
          return '#790000';
        case 'L2':
          return '#790000';
        case 'L3':
          return '#790000';
        case 'R4':
          return '#002E4B';
        case 'R5':
          return '#002E4B';
        case 'R6':
          return '#002E4B';
        default:
          return '#000000';
      }
    },
    genderColor() {
      switch (this.genderStatus){
        case 'F1':
          return '#FF000A';
        case 'F2':
          return '#CE333B';
        case 'F3':
          return '#8B5758';
        case 'E4':
          return '#426371';
        case 'E5':
          return '#006E9A';
        case 'E6':
          return '#007AC2';
        default:
          return '#666666';
      }
    },
    classColor() {
      switch (this.classStatus){
        case 'W1':
          return '#d30000';
        case 'W2':
          return '#98000C';
        case 'W3':
          return '#5B2C2D';
        case 'U4':
          return '#27374B';
        case 'U5':
          return '#003B75';
        case 'U6':
          return '#003D9F';
        default:
          return '#3F3F3F';
      }
    },
    opennessColor() {
      switch (this.opennessStatus){
        case 'O1':
          return '#FF3C43';
        case 'O2':
          return '#DB5D5F';
        case 'O3':
          return '#A27777';
        case 'N4':
          return '#8C9BA7';
        case 'N5':
          return '#72A0C3';
        case 'N6':
          return '#52A5DF';
        default:
          return '#B2B2B2';
      }
    }
  }
}
</script>

<style scoped>
@keyframes colorPoliticalChange {
  0% {
    fill: #FF7719; /* 시작 색상 */
  }
  8.3% {
    fill: #E38859;
  }
  16.7% {
    fill: #B69687;
  }
  25% {
    fill: #B2B2B2;
  }
  33.3% {
    fill: #507C7E;
  }
  41.6% {
    fill: #009095;
  }
  50% {
    fill: #00A3AA;
  }
  58.3% {
    fill: #009095;
  }
  66.6% {
    fill: #507C7E;
  }
  75% {
    fill: #B2B2B2;
  }
  83.3% {
    fill: #B69687;
  }
  91.6% {
    fill: #E38859;
  }
  100% {
    fill: #FF7719; /* 끝 색상 */
  }
}

@keyframes colorGenderChange {
  0% {
    fill: #FF000A;
  }
  8.3% {
    fill: #CE333B;
  }
  16.7% {
    fill: #8B5758;
  }
  25% {
    fill: #666666;
  }
  33.3% {
    fill: #426371;
  }
  41.6% {
    fill: #006E9A;
  }
  50% {
    fill: #007AC2;
  }
  58.3% {
    fill: #006E9A;
  }
  66.6% {
    fill: #426371;
  }
  75% {
    fill: #666666;
  }
  83.3% {
    fill: #8B5758;
  }
  91.6% {
    fill: #CE333B;
  }
  100% {
    fill: #FF000A;
  }
}

@keyframes colorClassChange {
  0% {
    fill: #d30000;
  }
  8.3% {
    fill: #98000C;
  }
  16.7% {
    fill: #5B2C2D;
  }
  25% {
    fill: #3F3F3F;
  }
  33.3% {
    fill: #27374B;
  }
  41.6% {
    fill: #003B75;
  }
  50% {
    fill: #003D9F;
  }
  58.3% {
    fill: #003B75;
  }
  66.6% {
    fill: #27374B;
  }
  75% {
    fill: #3F3F3F;
  }
  83.3% {
    fill: #5B2C2D;
  }
  91.6% {
    fill: #98000C;
  }
  100% {
    fill: #d30000;
  }
}

@keyframes colorOpennessChange{
  0% {
    fill: #FF3C43;
  }
  8.3% {
    fill: #DB5D5F;
  }
  16.7% {
    fill: #A27777;
  }
  25% {
    fill: #B2B2B2;
  }
  33.3% {
    fill: #8C9BA7;
  }
  41.6% {
    fill: #72A0C3;
  }
  50% {
    fill: #52A5DF;
  }
  58.3% {
    fill: #72A0C3;
  }
  66.6% {
    fill: #8C9BA7;
  }
  75% {
    fill: #B2B2B2;
  }
  83.3% {
    fill: #A27777;
  }
  91.6% {
    fill: #DB5D5F;
  }
  100% {
    fill: #FF3C43;
  }
}

@keyframes colorPolitical2Change{
  0% {
    fill: #790000;
  }
  16.7% {
    fill: #790000;
  }
  25% {
    fill: #000000;
  }
  33.3%{
    fill: #002E4B;
  }
  66.6% {
    fill: #002E4B;
  }
  75% {
    fill: #000000;
  }
  83.3% {
    fill: #790000;
  }
  100% {
    fill: #790000;
  }
}

.politicsColor {
  animation: colorPoliticalChange 5s infinite; /* 10초 동안 무한 반복 */
}

.genderColor {
  animation: colorGenderChange 5s infinite; /* 10초 동안 무한 반복 */
}

.classColor {
  animation: colorGenderChange 5s infinite; /* 10초 동안 무한 반복 */
}

.opennessColor {
  animation: colorOpennessChange 5s infinite; /* 10초 동안 무한 반복 */
}

.politicsColor2{
  animation: colorPolitical2Change 5s infinite; /* 10초 동안 무한 반복 */
}
</style>