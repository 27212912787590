<template>
  <div style="position: relative">
    <div class="chart-index">
      <div class="chart-index-text" v-html="leftText"></div>
      <div class="chart-index-text">{{ centerText }}</div>
      <div class="chart-index-text">{{ rightText }}</div>
    </div>
    <div class="chart-label">
      <div class="chart-label-text">{{ bigLeftText }}</div>
      <div class="chart-label-text">{{ bigRightText }}</div>
    </div>
    <apexchart type="bar" :options="chartOptions" :series="chartSeries" width="330" height="100"></apexchart>
  </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts';

export default {
  name: "ResultChart",
  components: {
    apexchart: VueApexCharts
  },
  props: {
    theme: String,
    score: Number
  },
  computed: {
    colorRanges() {
      const colorMap = {
        Politics: { negative: '#FF7719', positive: '#00A3AA' },
        Gender: { negative: '#FF000A', positive: '#007AC2' },
        Class: { negative: '#D30000', positive: '#003D9F' },
        Openness: { negative: '#FF3C43', positive: '#52A5DF' }
      };

      return [
        { from: -3, to: 0, color: colorMap[this.theme].negative },
        { from: 0, to: 3, color: colorMap[this.theme].positive }
      ];
    },
    chartOptions() {
      return {
        chart: {
          type: 'bar',
          height: '50px',
          toolbar: {
            show: false
          },
          events: {
            click: function (event) {
              event.preventDefault();
            }
          }
        },
        legend: {
          show: false
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'bottom',
            },
            horizontal: true,
            borderRadius: 20,
            borderRadiusApplication: 'end',
            distributed: true,
            barHeight: '80%',
            colors: {
              ranges: this.colorRanges,
              backgroundBarColors: ['#e5e5e5'],
              backgroundBarRadius: 20
            }
          }
        },
        dataLabels: {
          enabled: true,
          offsetX: 10,
          style: {
            fontSize: '20px',
            fontFamily: 'SUIT Variable'
          },
          formatter: function (val) {
            return Math.abs(val);
          }
        },
        xaxis: {
          categories: ['scores'],
          labels: {
            show: false
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          min: -3,
          max: 3,
          show: false
        },
        grid: {
          show: false
        },
        tooltip: {
          enabled: false
        }
      };
    },
    chartSeries() {
      return [{
        name: 'Scores',
        data: [this.score]
      }];
    },
    leftText() {
      const textMap = {
        Politics: 'LEFT',
        Gender: 'FEMINISM',
        Class: 'WORKING&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;',
        Openness: 'OPEN MINDED&nbsp;&nbsp;'
      };
      return textMap[this.theme];
    },
    centerText() {
      const textMap = {
        Politics: '정치',
        Gender: '젠더',
        Class: `계급`,
        Openness: '개방성'
      };
      return textMap[this.theme];
    },
    rightText() {
      const textMap = {
        Politics: 'RIGHT',
        Gender: 'EQUALISM',
        Class: 'UPPER-MIDDLE',
        Openness: 'CONSERVATIVE'
      };
      return textMap[this.theme];
    },
    bigLeftText(){
      const textMap = {
        Politics: '좌파',
        Gender: '페미',
        Class: '서민',
        Openness: '개방'
      };
      return textMap[this.theme];
    },
    bigRightText(){
      const textMap = {
        Politics: '우파',
        Gender: '이퀄',
        Class: '부유',
        Openness: '전통'
      };
      return textMap[this.theme];
    }
  }
}
</script>


<style scoped>
.vue-apexcharts{
  min-height: 80px !important;
  height: 80px !important;
}

.chart-index{
  position: absolute;
  top: 20px;
  left: 53%;
  transform: translateX(-50%);
  width: 280px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.chart-index-text{
  font-size: 12px;
  font-weight: bold;
  color: #939393
}

.chart-label{
  position: absolute;
  top: 45px;
  left: 53%;
  transform: translateX(-50%);
  width: 260px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
}

.chart-label-text{
  font-size: 18px;
  font-weight: 700;
  color: #949494
}

</style>