<template>
  <div class="container">
    <div class="screen" ref="screen">
      <div v-if="isLoading || isImageLoading" class="loading-screen">
        <LoadingSymbol/>
        <span style="color: white; font-size: 18px; font-weight: bold;">
              결과를 분석중입니다
            </span>
        <div class="loading-logo">
          <svg width="80" height="44" viewBox="0 0 505 227" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.33 89.04H51.04V69.91H0.669983V225.31H51.04V206.18H21.33V89.04Z" fill="white"/>
            <path d="M67.43 125.92H51.04V144.88H67.43V225.31H88.1V69.91H67.43V125.92Z" fill="white"/>
            <path d="M504.67 69.91H484.01V225.31H504.67V69.91Z" fill="white"/>
            <path d="M438.08 89.04H467.79V69.91H417.42V225.31H467.79V206.18H438.08V151.71H467.96V132.58H438.08V89.04Z" fill="white"/>
            <path d="M189.91 125.92H178.64V144.88H189.91V225.31H210.57V69.91H189.91V125.92Z" fill="white"/>
            <path d="M122.91 69.97V90.15C139.49 90.15 152.98 102.75 152.98 118.25L153 132.92H122.92V152.05H153.02L153.1 225.32L173.28 225.3L173.17 118.25C173.17 91.63 150.63 69.97 122.92 69.97H122.91Z" fill="white"/>
            <path d="M404.74 69.91H384.08V225.31H404.74V69.91Z" fill="white"/>
            <path d="M341.13 176.96L341.02 69.9L320.84 69.92L320.95 176.97C320.95 203.59 343.49 225.25 371.2 225.25V205.07C354.62 205.07 341.13 192.46 341.13 176.96Z" fill="white"/>
            <path d="M225.89 188.34H238.18V226.08H258.84V188.34H272.34V226.08H293V188.34H306.15V169.22H225.89V188.34Z" fill="white"/>
            <path d="M304.67 160.25V108.88C304.67 108.37 304.61 107.87 304.59 107.36V70.69C304.59 70.69 228.44 70.69 228.2 70.69V108.7C228.2 108.7 228.2 108.77 228.2 108.81V110.23L228.28 110.17V160.25C228.28 160.25 304.43 160.25 304.67 160.25ZM283.81 139.39H248.94V91.21H283.81V139.39Z" fill="white"/>
            <path d="M67.19 38.72L61.83 36.04C64.64 30.74 66.57 25.06 67.63 18.99C68.68 12.92 69.21 6.73 69.21 0.41H75.05C75.05 2.63 74.99 4.86 74.87 7.11C74.75 9.36 74.56 11.61 74.3 13.86C74.77 15.74 75.37 17.63 76.1 19.52C76.83 21.41 77.68 23.24 78.65 25C79.59 26.79 80.63 28.49 81.79 30.11C82.95 31.73 84.18 33.18 85.5 34.48L81.11 38.41C79.32 36.56 77.69 34.44 76.22 32.06C74.74 29.67 73.48 27.19 72.42 24.6C71.81 27.1 71.07 29.54 70.2 31.92C69.34 34.31 68.33 36.58 67.19 38.74V38.72ZM95.91 20.89V39.93H90.07V0H95.91V15.02H100.83V20.89H95.91Z" fill="white"/>
            <path d="M136.34 0H142.18C142.18 1.23 142.11 2.45 141.98 3.65C141.85 4.85 141.67 6.01 141.43 7.12C142.66 9.06 144.22 10.9 146.13 12.62C148.03 14.35 149.99 15.76 152.01 16.87L148.54 21.4C146.87 20.41 145.23 19.21 143.62 17.8C142.01 16.38 140.57 14.87 139.32 13.27C138.44 15.12 137.39 16.8 136.16 18.33C134.93 19.85 133.54 21.2 131.99 22.37L127.64 18.4C130.51 16.37 132.67 13.79 134.14 10.66C135.61 7.52 136.34 3.97 136.34 0ZM152.67 40.34H140.07C137 40.34 134.57 39.64 132.8 38.24C131.03 36.84 130.14 34.8 130.14 32.12C130.14 29.71 131.03 27.74 132.8 26.2C134.57 24.66 136.99 23.89 140.07 23.89H152.67C155.68 23.89 158.08 24.66 159.87 26.2C161.65 27.74 162.55 29.71 162.55 32.12C162.55 34.8 161.66 36.84 159.87 38.24C158.08 39.64 155.68 40.34 152.67 40.34ZM140.07 35.16H152.67C153.81 35.16 154.75 34.88 155.48 34.33C156.21 33.78 156.58 33.04 156.58 32.11C156.58 31.18 156.21 30.48 155.48 29.91C154.75 29.34 153.81 29.05 152.67 29.05H140.07C138.84 29.05 137.88 29.34 137.19 29.91C136.5 30.48 136.16 31.21 136.16 32.11C136.16 33.01 136.5 33.77 137.19 34.33C137.88 34.89 138.84 35.16 140.07 35.16ZM166.67 7.85V13.67H161.75V22.63H155.91V0H161.75V7.85H166.67Z" fill="white"/>
            <path d="M195.6 0.65H213.95V3.75C213.95 6.25 213.5 8.55 212.59 10.66C211.68 12.77 210.42 14.61 208.81 16.18C207.2 17.75 205.3 19.04 203.1 20.04C200.9 21.04 198.53 21.68 195.99 21.96C195.79 21.56 195.59 21.15 195.4 20.74C195.21 20.32 195.03 19.92 194.85 19.51C194.67 19.08 194.49 18.65 194.3 18.24C194.11 17.82 193.93 17.4 193.75 16.97C197.55 16.54 200.75 15.39 203.34 13.53C205.93 11.67 207.5 9.16 208.06 6.02H195.59V0.65H195.6ZM228.4 23.8V39.7H196.84V23.8H228.4ZM202.67 34.56H222.6V28.92H202.67V34.56ZM228.4 0V22H222.61V14.28H214.36V8.69H222.61V0H228.4Z" fill="white"/>
            <path d="M260 18.3H296.31V23.29H260V18.3ZM294.46 0.650024V5.64002H282.12C281.92 5.98002 281.7 6.30002 281.48 6.61002C281.26 6.92002 281.03 7.21002 280.8 7.49002C282.85 8.78002 285.19 9.81003 287.82 10.56C290.45 11.32 293.15 11.79 295.9 11.97L293.66 16.54C290.85 16.26 287.96 15.65 284.99 14.69C282.02 13.73 279.44 12.5 277.24 10.99C275.19 12.65 272.91 14.02 270.39 15.08C267.87 16.14 265.39 16.81 262.93 17.09L260.43 12.61C263.33 12.27 266.07 11.51 268.66 10.32C271.25 9.13002 273.48 7.57002 275.36 5.63002H261.84V0.650024H294.46ZM294.86 33.04C294.86 35.41 294 37.22 292.29 38.47C290.58 39.72 288.21 40.34 285.2 40.34H271.15C268.08 40.34 265.69 39.72 263.99 38.47C262.29 37.22 261.44 35.41 261.44 33.04C261.44 30.67 262.29 28.97 263.99 27.66C265.69 26.35 268.07 25.7 271.15 25.7H285.2C288.21 25.7 290.58 26.36 292.29 27.66C294 28.97 294.86 30.76 294.86 33.04ZM288.89 33.04C288.89 32.3 288.58 31.7 287.95 31.24C287.32 30.78 286.3 30.55 284.9 30.55H271.42C270.1 30.55 269.11 30.78 268.44 31.24C267.77 31.7 267.43 32.3 267.43 33.04C267.43 33.78 267.74 34.37 268.37 34.82C269 35.27 270.02 35.49 271.42 35.49H284.9C286.31 35.49 287.32 35.27 287.95 34.82C288.58 34.37 288.89 33.78 288.89 33.04Z" fill="white"/>
            <path d="M362.16 24.07V29.75H347.1V40.33H340.91V29.75H325.85V24.07H362.16ZM360.31 21.49H354.47V6.28002H327.69V0.650024H360.31V21.49Z" fill="white"/>
            <path d="M392.14 11.83V10.77C392.14 9.23 392.4 7.8 392.91 6.47C393.42 5.14 394.13 4.01 395.04 3.05C395.98 2.1 397.09 1.35 398.38 0.81C399.66 0.27 401.08 0 402.63 0C404.18 0 405.56 0.27 406.84 0.81C408.12 1.35 409.24 2.1 410.18 3.05C411.12 4.01 411.85 5.15 412.38 6.47C412.91 7.79 413.17 9.23 413.17 10.77V11.83C413.17 13.4 412.91 14.84 412.38 16.15C411.85 17.46 411.12 18.59 410.18 19.55C409.24 20.51 408.13 21.24 406.84 21.77C405.55 22.3 404.15 22.55 402.63 22.55C401.11 22.55 399.66 22.29 398.37 21.77C397.08 21.25 395.97 20.51 395.03 19.55C394.12 18.6 393.41 17.46 392.9 16.15C392.39 14.84 392.13 13.4 392.13 11.83H392.14ZM425.94 25.74V40.34H420.15V31.24H393.99V25.74H425.95H425.94ZM397.89 10.77V11.83C397.89 13.4 398.31 14.73 399.14 15.8C399.97 16.88 401.14 17.42 402.63 17.42C404.12 17.42 405.25 16.88 406.1 15.8C406.95 14.72 407.37 13.4 407.37 11.83V10.77C407.37 9.2 406.95 7.87 406.1 6.79C405.25 5.71 404.1 5.17 402.63 5.17C401.16 5.17 399.97 5.71 399.14 6.79C398.31 7.87 397.89 9.2 397.89 10.77ZM420.15 3.88V0H425.94V23.89H420.15V18.99H414.27V13.58H420.15V9.33H414.27V3.88H420.15Z" fill="white"/>
            <path d="M443.5 13.03H436.83V6.28003H443.5V13.03ZM443.5 32.99H436.83V26.24H443.5V32.99Z" fill="white"/>
          </svg>

        </div>
      </div>
      <div v-if="user" class="result" >
        <div class="title" ref="top">
          <div class="title-nickname">
            {{user.nickname}}님의 사상검증 결과
          </div>
          <div class="title-type">
            {{rearrangeResultTypeLettersOnly(user.result_type)}}
          </div>
        </div>
        <ResultSymbol :current-status="typeCode"/>
        <div class="chart-container" v-if="user">
          <ResultChart :theme="'Politics'" :score="calculateScore(getScoreForTheme(user.result_type, 'L') || getScoreForTheme(user.result_type, 'R'))" />
          <ResultChart :theme="'Gender'" :score="calculateScore(getScoreForTheme(user.result_type, 'F') || getScoreForTheme(user.result_type, 'E'))" />
          <ResultChart :theme="'Class'" :score="calculateScore(getScoreForTheme(user.result_type, 'W') || getScoreForTheme(user.result_type, 'U'))" />
          <ResultChart :theme="'Openness'" :score="calculateScore(getScoreForTheme(user.result_type, 'O') || getScoreForTheme(user.result_type, 'C'))" />
          <div class="graph-guide">
            <div class="graph-text">
              <p>※ 각 영역은 지지하는 성향이 더 강할수록 높은 점수를 받고, 점수가 높을수록 색깔도 진해집니다.</p>
            </div>
            <div class="graph-text">
              <p>※ 결과에서 사용하는 용어들은 본 테스트와 프로그램 내에서 사용하기 위해 정의한 것으로, 실제 용례와 다를 수 있습니다.</p>
            </div>
          </div>
        </div>

        <div class="share-buttons" ref="shareButtons">
          <img src="@/assets/the-community-bottom-banner.png" alt="wavve-banner"
               style="width: 100%; -webkit-filter: drop-shadow(0px -6px 2px rgba(0,0,0,0.1)); filter: drop-shadow(0px -6px 2px rgba(0,0,0,0.1));"
               @load="imageLoadComplete">
          <div class="start-new-button">
            <div class="new-button" style="background-color: #0050ff" @click="goWavve">이용권 첫 구매 100원으로 보러가기</div>
          </div>
          <hr style="height: 1px; background-color: #bbbbbb; border: none; width: 330px; margin: 15px 0 35px 0">
          <div class="share-buttons">
            <div class="new-button" @click="goFirstPage">사상검증 새로하기 시작하기</div>
            <div class="new-button" style="background-color: #FEE500; color: #000000;" @click="shareKakao">카카오로 결과 공유하기</div>
            <div class="new-button" style="background-color: #FFFFFF; border: 1px solid #1f1f1f; color: #000000;" @click="copyLinkToClipboard">링크로 결과 공유하기</div>
          </div>
        </div>

        <div class="guide" ref="guide">
          <hr style="height: 1px; background-color: #bbbbbb; border: none; width: 330px; margin: 20px 0">
          <h3>각 영역은 어떻게 측정되나요?</h3>
          <div class="toggle-section">
            <div class="title-guide" @click="toggleSection('politics')">
              정치 영역
              <span class="toggle-icon">{{ sectionStates.politics ? '-' : '+' }}</span>
            </div>
            <div class="disclaimer"  ref="politicsDisclaimer" v-show="sectionStates.politics">
                <strong>정치</strong> 영역은 <strong>정부의 역할에 대한 태도</strong>를 측정합니다.<br><br>
                정부가 적극적으로 부의 재분배를 통해 빈부격차를 줄이고, 복지제도를 통한 안전망을 확보해야한다는 ‘큰 정부’의 입장일수록 <span style="font-weight: bold; color: #933c3c">빨간색의 ‘좌파’</span>로 분류됩니다.<br><br>
                정부가 개인의 노력과 자유를 최대한 보장하고, 자유시장경제의 경쟁을 통한 성장을 추구 해야 한다는 ‘작은 정부’의 입장일수록 <span style="font-weight: bold; color: #1b3460">파란색의 ‘우파’</span>로 분류됩니다.
            </div>
          </div>
          <div class="toggle-section">
            <div class="title-guide" @click="toggleSection('gender')">
              젠더 영역
              <span class="toggle-icon">{{ sectionStates.gender ? '-' : '+' }}</span>
            </div>
            <div class="disclaimer"  ref="genderDisclaimer" v-show="sectionStates.gender">
                <strong>젠더</strong> 영역은 <strong>페미니즘 일반에 대한 태도</strong>를 측정합니다.<br><br>
                현대사회에도 여전히 남성의 기득권이 유지되고 있기 때문에, 여성에 대한 차별을 개선해 나가야 한다는 전제에 동의할수록 <span style="font-weight: bold; color: #933c3c">빨간색의 ‘페미니즘’</span>으로 분류됩니다.<br><br>
                반대로, 이미 여성에 대한 일방적인 차별은 대부분 해소되었으며, 두 성별 각각이 경험하는 세부적인 불평등을 동등하게 해결해야 하므로 여성 차별만을 주장하는 것은 ‘역차별’이 라는 입장일수록 <span style="font-weight: bold; color: #1b3460">파란색의 ‘이퀄리즘’</span>으로 분류됩니다.<br><br>
                <span style="font-size: 12px;">* '이퀄리즘'은 학문으로 분류된 용어는 아니며, ‘페미니즘’에 동의하지 않는 진영이 위와 같은 취지로 언급한 용어를 차용하였습니다.</span>
            </div>
          </div>
          <div class="toggle-section">
            <div class="title-guide" @click="toggleSection('class')">
              계급 영역
              <span class="toggle-icon">{{ sectionStates.class ? '-' : '+' }}</span>
            </div>
            <div class="disclaimer" ref="classDisclaimer" v-show="sectionStates.class">
                <strong>계급</strong> 영역은 영역은 현재의 경제적 상황이 아닌, <strong> 어릴 적의 경제적 출신과 태도</strong>를 측정합니다.<br><br>
                어린 시절부터 경제적인 소득이 적은 환경 출신이자 이와 연관된 태도를 많이 유지할수록 <span style="font-weight: bold; color: #933c3c">빨간색의 ‘서민’</span>으로 분류됩니다.<br><br>
                어린 시절부터 경제적으로 여유로운 환경에서 자라고, 금전적인 문제를 겪을 기회가 적은 입장일수록 <span style="font-weight: bold; color: #1b3460">파란색의 ‘부유’</span>로 분류됩니다.
            </div>
          </div>
          <div class="toggle-section">
            <div class="title-guide" @click="toggleSection('openness')">
              개방성 영역
              <span class="toggle-icon">{{ sectionStates.openness ? '-' : '+' }}</span>
            </div>
            <div class="disclaimer"  ref="opennessDisclaimer" v-show="sectionStates.openness">
                <strong>개방성</strong> 영역은 사회적 소수자와 새로운 윤리규범에 대해 어떤 입장을 가지고 있는지를 측정합니다<br><br>
                사회적 소수자를 위한 정책을 지지하고, 기존의 윤리규범을 대체하는 새로운 윤리규범에 거부감이 적은 입장일수록 <span style="font-weight: bold; color: #933c3c"> 빨간색의 ‘개방적’</span>으로 분류됩니다.<br><br>
                소수자보다는 다수의 입장을 중시하고, 새로운 질서보다는 기존의 윤리규범에 더 높은 가치를 부여하는 입장일수록 <span style="font-weight: bold; color: #1b3460">파란색의 ‘전통적’</span>으로 분류됩니다.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import supabase from '@/helper/supabaseClient.js'
import LoadingSymbol from "@/components/LoadingSymbol";
import ResultSymbol from "@/components/ResultSymbol";
import ResultChart from "@/components/ResultChart";

import { useKakao } from "vue3-kakao-sdk";


export default {
  name: "ResultPage",
  components: {
    ResultSymbol,
    LoadingSymbol,
    ResultChart,
  },
  data() {
    return {
      user: null,
      isLoading: true,
      typeCode: '',
      sectionStates: {
        politics: true,
        gender: true,
        class: true,
        openness: true,
      },
      isImageLoading: true,
      lastScrollPosition: 0,
      throttleTopTimer: null,
      throttleSectionTimer: null,
      isProgrammaticScroll: false,
    }
  },
  setup() {
    const { kakao } = useKakao();
    const currentUrl = window.location.href;

    const shareKakao = () => {
      kakao.value.Link.sendDefault({
        objectType: 'feed',
        content: {
          title: `[더 커뮤니티] 친구의 사상검증 결과는?`,
          description: '너와 나의 사상 점수를 지금 확인해보세요!',
          imageUrl: 'https://i.imgur.com/kmo2wR9.jpeg',
          link: {
            mobileWebUrl: currentUrl,
            webUrl: currentUrl
          }
        },
      });
    };
    return { shareKakao };
  },
  async mounted() {
    window.scrollTo(0, 0);
    const screenElement = this.$refs.screen;
    if (screenElement) {
      screenElement.addEventListener('scroll', this.handleScroll);
    }
    const referralCode = this.$route.query.referral;
    if (referralCode) {
      await this.fetchUserData(referralCode);
    }
  },
  beforeUnmount() {
    if (this.$refs.screen) {
      this.$refs.screen.removeEventListener('scroll', this.handleScroll);
    }
    if (this.throttleSectionTimer || this.throttleTopTimer) {
      clearTimeout(this.throttleSectionTimer);
      clearTimeout(this.throttleTopTimer);
    }
  },
  methods: {
    handleScroll() {
      console.log('scrolling');
      if (this.isProgrammaticScroll) {
        this.isProgrammaticScroll = false;
        return;
      }

      const currentScrollPosition = this.$refs.screen.scrollTop;

      // 화면 최상단에서 아래로 스크롤 시작 시 shareButtons로 이동
      if (this.lastScrollPosition === 0 && currentScrollPosition > 0) {
        this.scrollToSection('shareButtons');
      } else {
        // shareButtons의 상대적인 위치 계산
        const shareButtonsRect = this.$refs.shareButtons.getBoundingClientRect();
        const screenRect = this.$refs.screen.getBoundingClientRect();
        const shareButtonsTopRelative = shareButtonsRect.top - screenRect.top;

        // shareButtons가 뷰포트 상단에 위치하고, 사용자가 위로 스크롤할 경우 최상단으로 이동
        if (shareButtonsTopRelative <= 0 && currentScrollPosition < this.lastScrollPosition && currentScrollPosition === 0) {
          this.scrollToTopSmooth();
        }
      }

      this.lastScrollPosition = currentScrollPosition;
    },
    scrollToSection(refName) {
      if (!this.throttleSectionTimer) {
        this.throttleSectionTimer = setTimeout(() => {
          const section = this.$refs[refName];
          if (section) {
            const top = section.offsetTop - this.$refs.screen.offsetTop;
            this.isProgrammaticScroll = true;
            this.$refs.screen.scrollTo({ top: top, behavior: 'smooth' });
          }
          // 타이머 리셋
          clearTimeout(this.throttleSectionTimer);
          this.throttleSectionTimer = null;
        }, 100); // 스로틀링 간격 100ms
      }
    },
    scrollToTopSmooth() {
      if (!this.throttleTopTimer) {
        this.throttleTopTimer = setTimeout(() => {
          this.isProgrammaticScroll = true;
          this.$refs.screen.scrollTo({ top: 0, behavior: 'smooth' });

          // 타이머 리셋
          clearTimeout(this.throttleTopTimer);
          this.throttleTopTimer = null;
        }, 3000); // 100ms 간격으로 스로틀링
      }
    },
    isElementInViewport(el, onlyTop = false) {
      const rect = el.getBoundingClientRect();
      const screenRect = this.$refs.screen.getBoundingClientRect();
      return onlyTop
          ? rect.top >= screenRect.top && rect.bottom <= screenRect.bottom
          : rect.bottom > screenRect.top && rect.top < screenRect.bottom;
    },
    imageLoadComplete() {
      this.isImageLoading = false;
    },
    async fetchUserData(referralcode) {
      try {
        const { data, error } = await supabase
            .from('users')
            .select('*')
            .eq('referralcode', referralcode)
            .single();

        if (error) throw error;
        this.user = data;
        this.typeCode = this.rearrangeResultType(this.user.result_type);
        this.isLoading = false;
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    },
    rearrangeResultType(resultType) {
      const themeOrder = ['politics', 'gender', 'class', 'openness'];
      const themeMapping = {
        'L': 'politics', 'R': 'politics',
        'F': 'gender', 'E': 'gender',
        'W': 'class', 'U': 'class',
        'O': 'openness', 'C': 'openness'
      };

      const results = resultType.match(/([LREFWUCO]\d+)/g);
      if (!results) {
        return resultType; // 원본 문자열 반환, 패턴과 일치하는 부분이 없는 경우
      }

      // 결과를 주제별로 그룹화
      const groupedResults = {};
      results.forEach(result => {
        const theme = themeMapping[result.charAt(0)];
        groupedResults[theme] = result;
      });

      // 정의된 순서대로 결과 재배열
      return themeOrder.map(theme => groupedResults[theme]).join('');
    },
    rearrangeResultTypeLettersOnly(resultType) {
      return this.rearrangeResultType(resultType).replace(/\d+/g, ''); // 숫자 제거
    },
    getScoreForTheme(resultType, themeLetter) {
      const match = resultType.match(new RegExp(`${themeLetter}(\\d+)`));
      return match ? Number(match[1]) : null;
    },
    calculateScore(score) {
      // 점수 변환 로직
      return score <= 3 ? score - 4 : score - 3;
    },
    toggleSection(section) {
      this.sectionStates[section] = !this.sectionStates[section];
      // max-height 조절 로직 추가
      let disclaimer = this.$refs[section + 'Disclaimer'];
      if (this.sectionStates[section]) {
        disclaimer.style.maxHeight = 600 + 'px';
      } else {
        disclaimer.style.maxHeight = '0';
      }
    },
    goWavve() {
      window.open('https://www.wavve.com/player/vod?programid=C9901_C99000000124&landing=season', '_blank');
    },
    goFirstPage(){
      this.$router.push('/home')
    },
    copyLinkToClipboard() {
      const link = window.location.href;
      navigator.clipboard.writeText(link);
      alert('링크가 복사되었습니다');
    },
  }
}
</script>



<style scoped>
.container {
  display: flex;
  flex-direction: column; /* 컬럼 방향으로 정렬 */
  justify-content: flex-start; /* 상단부터 시작 */
  align-items: center; /* 가운데 정렬 (좌우) */
  max-width: 480px;
  overflow: hidden;
  background-color: #FCFCFC;
  margin: 0 auto;
}

.screen {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;

}

.loading-screen{
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.9);
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-logo{
  position: absolute;
  bottom: 30px;
}

.title{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
}

.title-nickname{
  font-size: 16px;
  font-weight: bold;
  color: #000000;
}

.title-type{
  position: relative;
  font-size: 42px;
  font-weight: 900;
  color: #000000;
  margin-bottom: 10px;
}

.chart-container{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.result{
  height: 800px;
}

.graph-guide{
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  margin-top: 40px;
}
.graph-text{
  color: #6e6e6e;
  font-size: 12px;
  line-height: 1.4;
}

.toggle-section{
  width: 300px; /* 폭 제한 */
  padding: 10px 0;
}

.guide{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
}

.title-guide{
  font-size: 14px;
  font-weight: bold;
  color: #6e6e6e;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 10px 10px;
  border-bottom: 1px solid #e0e0e0; /* 구분선 */
}

.toggle-icon {
  margin-left: 10px;
  font-size: 20px;
  font-weight: bold;
}

.disclaimer{
  font-size: 12px;
  color: #707070;
  font-weight: 500;
  line-height: 1.8;
  transition: max-height 1s ease-out;
  overflow: hidden;
  max-height: 500px;
  padding: 10px 10px 20px 10px;
  text-align: left;
}

.show {
  max-height: 500px; /* 필요한 최대 높이 설정 */
}

h4{
  margin: 10px 0;
}

p{
  margin: 0;
  margin-bottom: 10px;
}

.floating{
  position: fixed;
  bottom: 40px;
  right: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #444444;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  color: #FFFFFF;
  font-size: 14px;
  box-shadow: 4px 4px 10px 0px rgb(0 0 0 / 40%);
}

.new-button{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #070707;
  font-size: 14px;
  font-weight: 600;
  color: #FFFFFF;
  padding: 16px 20px;
  border: none;
  border-radius: 8px;
  width: 260px;
  margin-bottom: 15px;
}

.start-new-button{
  padding-top: 10px;
}

.share-buttons{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
